.organization-info-content {
    text-align: right;
    padding: 0px 20px 8px 0px;
    margin-bottom: 56px;
    margin-top: 50px;
}

.organization-name-avatar {
    width: 40px;
    height: 40px;
    margin-left: auto;
    border-color: #fff;
    border-radius: 2px;
}

.organization-name-text {
    font-size: 14pt;
    font-weight: 400;
    color: #fff;
    margin: 10px 0 0 0;
}

.organization-name-user-text {
    font-size: 12pt;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
    margin: 0 0 0 0;
}

.organization-name-avatar img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}