.good-upload-popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 1000;
  }
  
  .good-upload-popup.open {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .good-upload-popup-content {
    background-color: #141414;
    min-width: 400px;
    padding: 20px;
    border-radius: 10px;
    color: white;
  }
  
  .good-upload-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  .good-upload-form-group {
    margin-bottom: 10px;
  }
  
  .good-upload-label {
    font-weight: bold;
  }

  .good-button-group{
    display: flex;
  }
  
  .good-upload-input {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }
  
  .good-upload-button {
    padding: 10px 20px;
    margin: 10px;
    border: none;
    background-color: #23817d;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .good-upload-button:hover {
    background-color: #114b48;
  }
  