.product-list-container {
    margin: 20px;
  }
  
  .product-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .product-table th, .product-table td {
    border: 1px solid #ffffff;
    padding: 8px;
    text-align: left;
  }
  
  .product-table th {
    background-color: #ffffff;
  }
  
  .open-button {
    background-color: transparent;
    text-decoration: underline;
    color: rgb(0, 0, 0);
    font-size: 14px;
    padding: 6px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .open-button:hover {
    background-color: #ffffff;
  }