.dropdown-menu {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-menu-toggle {
    background-color: #f8f9fa;
    color: #212529;
    padding: 8px 12px;
    padding-right: 24px;
    cursor: pointer;
  }

  .dropdown-menu-toggle:hover {
    background-color: #e9ecef;
  }
  
  
  .dropdown-menu-list {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 4px;
    padding: 0;
    list-style: none;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-top: none;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .dropdown-menu-item {
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .dropdown-menu-item:hover {
    background-color: #e9ecef;
  }