.goods-header-panel{
 background-color: rgb(255, 255, 255);
 width: 100%;
 height: 75px;
 border-bottom: 2px solid #d1d1d1;
 display: flex;
 align-items: center; 
 justify-content: flex-start; 
}

.product-upload-group-button{
    background-color: #fff;
    margin-right: 0;
    margin-left: auto;
}

.product-upload-button{
    background-color: #141414;
    font-size: 14px;
    height: 34px;
    padding: 10px;
    width: auto;
    justify-self: flex-end;
    margin-right: 4px;
    margin-left: 0px;
}

.product-upload-button:hover{
    background-color: #141414;
    font-size: 14px;
    color: #23817d;
    height: 34px;
    padding: 10px;
    width: auto;
}

.product-view-select{
    margin-right: 0px;
    background-color: #fff;
    font-size: 14px;
    height: 34px;
    width: 34px;
    fill: #141414; 
}

.product-view-select svg{
    margin: 0;
    padding: 0;    
}

.product-view-select:hover{
    background-color: #23817d;
}

.product-view-select:disabled{
    background-color: #141414;
    fill: white; 
    font-size: 14px;
    padding: 0;
    cursor: default;
}