.login-container {
    width: 400px;
    height: 290px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #2e2e2e;
    border-radius: 5px;
    background-color: #141414;
    color: #fff;
  }
  
  .form-group {
    margin-bottom: 15px;
  }

  .login-header{
    margin-top: 4px;
    width: 100%;
    text-align: center;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input {
    width: calc(100% - 18px);
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    width: 100%;
    margin-top: 18px;
    padding: 18px;
    background-color: #23817d;
    font-size: 20px;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #114b48;
  }