.report-category {
    width: 100%;
    height: 32px;
    margin-top: 25px;
    color: #ffffff;
    align-self: center;
    background-color: #114b48;
    outline-width: 0;
    text-align: left;
    border: 0px solid black;
    padding-left: 6px;
    font-size: 15pt;
    font-weight: 300;
    line-height: 32px;
}

.integration-block {
    margin-top: 28px;
    background-color: #fff;
    box-shadow: 0px 0px 10px #b9b9b9;
    border-radius: 2px;
    width: 128px;
    height: 128px;
    padding: 14px 0 0 0;
    border: 1px solid rgb(191, 191, 191);
    text-align: center;
    line-height: 10pt;
}

.integration-block-image {
    background-color: #333333;
    margin: 0 auto;
    border-radius: 4px;
    width: 100px;
    height: 80px;
    overflow: hidden;
}

.integration-block-holder {
    background-color: #333333;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.integration-block-holder img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}