.button-report {
    font-size: 12pt;
    text-align: left;
    text-decoration: underline;
    width: 100%;
    border: 0px solid black;
    border-radius: 0px;
    cursor: pointer;
    height: auto;
    padding: 2px;
    background-color: transparent;
    color: black;
    margin-top: 4px;
}

.button-report:hover {
    font-weight: 500;
    background-color: #ebebeb;
}