.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    z-index: 2;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    min-width: 200px;
    min-height: 200px;
    background-color: #141414;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    font-size: 12px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .loading-circle {
    width: 50px;
    height: 50px;
    border: 5px solid #ccc;
    border-radius: 50%;
    border-top-color: #23817d;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }