.video-background {
    position: relative;
    width: 100%;
    height: 100vh; /* Высота видео будет равна высоте экрана */
    overflow: hidden;
  }
  
  .video-background__video {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; /* Установка индекса z для помещения видео под другие элементы */
  }
  
  .video-background__content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1; /* Устанавливаем индекс z для дочерних элементов, чтобы они были поверх видео */
  }