.setting-group{
 text-align: left;
 
}

.setting-parameter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 300px;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 2px;
    color: rgb(66, 66, 66);
}

.setting-parameter-name{
    font-weight: 600;
}

.settings-tittle-group{
    display: flex;
}

.setting-edit-button{
    font-size: 12px;
    width: auto;
    min-height: 0px;
    margin: 0px;
    padding: 0px;
    text-decoration: underline;
    align-self: center;
    background-color: transparent;
    color: black;
    margin-left: 32px;
}

.setting-edit-button:hover{
    background-color: transparent;
}