.sidebar-container{
    min-height: 100vh;
    height: 100%;
    width: 200px;
    position: sticky;
    top: 0;
    left: 0;
    background-color: #141414;
    padding-top: 20px;
}

.sidebar-button{
    background-color: #141414;
    border-radius: 0px;
    text-align: left;
    font-size: 15px;
    padding: 6px 8px 6px 16px;
    margin: 0px;
    color: rgba(255, 255, 255, 0.5);
}

.sidebar-button:hover{
    color: rgba(255, 255, 255, 1);
}